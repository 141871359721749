import type { ComponentType } from "react"
import { useEffect, useState } from "react"

export function BannerComponent(Component): ComponentType {
    return (props) => {
        const [isHidden, setIsHidden] = useState(false)

        if (!navigator.cookieEnabled) {
            return null
        }
        useEffect(() => {
            const gaCookie = document.cookie
                .split(";")
                .find((c) => c.trim().startsWith("_ga="))
            if (gaCookie) {
                setIsHidden(true)
            }
        }, [])

        return !isHidden && <Component {...props} />
    }
}
